// webSocketService.js
/* global WebSocket console clearTimeout setTimeout*/
class SocketService {
  constructor(url, options = {}) {
    this.url = url;
    this.socket = null;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = options.maxReconnectAttempts || 5; // Default to 5 reconnection attempts
    this.reconnectInterval = options.reconnectInterval || 3000; // Default to 3000ms between reconnections
    this.reconnectTimeout = null;
    this.messageQueue = []; // Queue to store messages when WebSocket is not open

    // Event handlers passed in through options
    this.onMessage = options.onMessage || function () {};
    this.onOpen = options.onOpen || function () {};
    this.onClose = options.onClose || function () {};
    this.onError = options.onError || function () {};

    this.initWebSocket();
  }

  initWebSocket() {
    this.socket = new WebSocket(this.url);

    // Connection opened
    this.socket.onopen = (event) => {
      console.log("WebSocket is open now.", event);
      this.reconnectAttempts = 0; // Reset reconnection attempts
      clearTimeout(this.reconnectTimeout); // Clear any pending reconnection timeout
      if (this.onOpen) {
        this.onOpen(event);
      }

      // Send any queued messages after connection is opened
      this.flushMessageQueue();
    };

    // Listen for messages
    this.socket.onmessage = (event) => {
      console.log("Message from server:", event.data);
      if (this.onMessage) {
        this.onMessage(JSON.parse(event.data));
      }
    };

    // Handle errors
    this.socket.onerror = (error) => {
      console.error("WebSocket encountered an error:", error);
      if (this.onError) {
        this.onError(error);
      }
    };

    // Connection closed
    this.socket.onclose = (event) => {
      console.log("WebSocket is closed now.", event);
      if (this.onClose) {
        this.onClose(event);
      }
      // Attempt reconnection if it's not a manual close
      if (event.code !== 1000) {
        this.attemptReconnection();
      }
    };
  }

  // Attempt reconnection logic
  attemptReconnection() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts += 1;
      console.log(`Attempting reconnection... (${this.reconnectAttempts}/${this.maxReconnectAttempts})`);

      this.reconnectTimeout = setTimeout(() => {
        this.initWebSocket();
      }, this.reconnectInterval);
    } else {
      console.log("Maximum reconnection attempts reached. Giving up.");
    }
  }

  // Send message to the WebSocket server
  sendMessage(message) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.log("WebSocket is not open. Storing message in queue.");
      const msg = JSON.stringify(message);
      if (!this.messageQueue.find((item) => item === msg)) {
        this.messageQueue.push(msg);
      }
    }
  }

  // Flush the message queue and send all queued messages when the WebSocket is connected
  flushMessageQueue() {
    while (this.messageQueue.length > 0) {
      const message = this.messageQueue.shift();
      this.socket.send(message);
      console.log("Sent queued message:", message);
    }
  }

  // Close WebSocket connection
  closeWebSocket() {
    if (this.socket && (this.socket.readyState === WebSocket.OPEN || this.socket.readyState === WebSocket.CONNECTING)) {
      this.socket.close(1000, "Manual close"); // Send a code 1000 to indicate a clean manual close
    }
  }
}
export default SocketService;
