/* global console Excel window localStorage */

import socketService from "../socketService";

/**
 * The typed data will be handled by Melody.
 * @customfunction
 * @param {string | number} search Value for melody search.
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @requiresAddress
 * @returns {Promise<string | number>} Melody search result.
 */
export async function askMelody(value, invocation) {
  try {
    const scopeId = localStorage.getItem("scopeId");
    if (!scopeId) {
      return `Please select file!!!`;
    }
    await Excel.run(async (context) => {
      // Get the currently active worksheet
      context.workbook.worksheets.getActiveWorksheet();

      // Get the currently active cell
      // const cell = context.workbook.getSelectedRange();
      // cell.load("address");
      const address = invocation.address;
      // Sync the context to fetch the cell properties
      await context.sync();
      const addressParts = address.split("!");
      const cellReference = addressParts.length > 1 ? addressParts[1] : address;
      // Log the cell address
      if (addressParts[1]) {
        window.cellAddress = addressParts[1];
      }
      const socket = new socketService("wss://fission-melody.cognaize.net/melody-iq-chat-dev", {
        onMessage: async (response) => {
          try {
            await Excel.run(async (context) => {
              const sheet = context.workbook.worksheets.getActiveWorksheet();
              const range = sheet.getRange(address);
              context.trackedObjects.add(range);
              range.load("values");
              await context.sync();

              if (response.status === "FAIL") {
                range.values = [["Failed to query"]];
                await context.sync();
              } else {
                range.format.autofitColumns();
                range.values = [[response.message]];
                await context.sync();
              }
              context.trackedObjects.remove(range);
              // console.log(`Updated cell ${address} to value ${concatenatedValue}`);
            });
          } catch (error) {
            console.error(`Error updating cell ${address}: ${error}`);
          }
          console.log(response, "response");

          socket.closeWebSocket();
        },
      });
      //scopeId: "ffffffffffffffffffffffff"
      socket.sendMessage({ scopeId, message: value });
      console.log(`Function called from cell: ${address}`, cellReference);
      console.log(`Emitted cellSelected event with ${cellReference}`);
    });

    return `Searching for : ${value}`;
  } catch (error) {
    console.error(error, "erororororor");
    return `Error: ${error.message}`;
  }
}

CustomFunctions.associate("ASKMELODY", askMelody);